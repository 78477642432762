import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/maria.jpg`} alt="" />
      </Link>
      <header>
        <h2>Maria J. Diamantopoulou</h2>
        <p><a href="mailto:mdiamant@for.auth.gr">mdiamant@for.auth.gr</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <span>
        Hello everyone, I&apos;m Maria J. Diamantopoulou.
      </span>
      <p style={{ marginTop: '10px' }}>
        I am an Assistant Professor in the <a href="https://www.for.auth.gr" target="_blank" rel="noreferrer">School of Forestry and Natural Environment</a>, Faculty of Agriculture Forestry and Natural Environment, Aristotle University of Thessaloniki, Greece.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/publications" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy;2024 | Maria J. Diamantopoulou | <Link to="/">mdiamantopoulou.gr</Link>.</p>
    </section>
  </section>
);

export default SideBar;
