import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faLeanpub } from '@fortawesome/free-brands-svg-icons/faLeanpub';
import { faResearchgate } from '@fortawesome/free-brands-svg-icons/faResearchgate';

// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://scholar.google.com/citations?user=EKucrLMAAAAJ',
    label: 'Google Scholar',
    icon: faLeanpub,
  },
  {
    link: 'https://www.researchgate.net/profile/Maria-Diamantopoulou-2',
    label: 'ResearchGate',
    icon: faResearchgate,
  },
  {
    link: 'mailto:mdiamant@for.auth.gr',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
